import React, { useEffect } from "react";
// import HeaderNew from "../../components/UI/HeaderNew";
import Footer from "../LandingPage/Footer";
import about from "../../assets/images/conference/about.png";
import AboutInfo from "./AboutInfo";
import AboutBottomImage from "./AboutBottomImage";
import OtherHeader from "../../components/UI/Headers/OtherHeader";

function About() {
  useEffect(() => {
    // should this be removed? theres gotta be a better way to do this. If not, I don't think its worth the messy code
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();
        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  });
  return (
    <>
      <div className="overflow-visible">
        {/* {mommyMonitorAnimatedLogo} */}
        <OtherHeader
        title={"About 2022 Conference"}
        headerImage={about}
        />
        <AboutInfo />
        <AboutBottomImage />

        <Footer/>

      </div>
    </>
  );
}

export default About;
