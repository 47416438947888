import React, { useEffect } from "react";
import Footer from "../LandingPage/Footer";
import AboutPosterPresentations from "./AboutPosterPresentations";
import VirtualHeader from "../../assets/images/conference/VirtualHeader.png";
import OtherHeader from "../../components/UI/Headers/OtherHeader";

function PosterPresentations() {
  useEffect(() => {
    // should this be removed? theres gotta be a better way to do this. If not, I don't think its worth the messy code
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();
        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  });
  return (
    <>
      <div className="overflow-auto md:overflow-scroll ...">
        <OtherHeader
          title={"Poster Presentations"}
          description={""}
          headerImage={VirtualHeader}
        />
        <AboutPosterPresentations />
        <Footer />
      </div>
    </>
  );
}

export default PosterPresentations;
