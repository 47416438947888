import React from "react";
import ConferenceHeader from "../../../assets/images/conference/ConferenceHeader.jpeg";
import NavBar from "../Navigation/NavBar";

function HomeHeader() {
  return (
    <div className="relative bg-primary overflow-visible">
      <div className="sm:h-full sm:w-full max-w-7xl sm:overflow-hidden w-full md:w-auto">
        <div className="absolute inset-0">
          <img
            className="h-full w-full object-cover"
            src={ConferenceHeader}
            alt="header"
          ></img>
          <div className="absolute inset-0 bg-primary-light opacity-75 mix-blend-multiply" />
        </div>
      </div>
      <div className="relative pt-6 pb-16 sm:pb-24">
        
        <NavBar />
        
        <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
          
          <div className="text-center">
            <h1 className="text-6xl tracking-tight text-teritiary sm:text-5xl sm:tracking-tight md:text-6xl md:tracking-wide">
              <span className="block xl:inline font-bold pb-4">
                RACIALIZED MATERNAL{" "}
              </span>
              <span className="block xl:inline text-yellow-300/75">
                HEALTH CONFERENCE
              </span>{" "}
            </h1>
            <h2 className="pt-10 tracking-tight text-teritiary  tracking-normal">
              <span className="sm:text-xl md:text-2xl text-3xl block xl:inline font-bold break-normal">
                Canada's Leading Forum for Racialized Perinatal Healthcare.
              </span>{" "}
            </h2>
            <h2 className="pt-4 tracking-tight text-teritiary  tracking-normal sm:text-md md:text-xl text-2xl block xl:inline">
            Creating space for people who care about equitable perinatal health.

            </h2>
            <h1 className="mb-3 pt-10 mx-auto text-base text-teritiary md:mt-5 text-4xl md:text-4xl md:max-w-5xl">
              November 18<sup class="font-features sups">th</sup> 2022
            </h1>
            <h1 className="mb-3 mx-auto text-base text-teritiary md:mt-5 text-4xl md:text-3xl md:max-w-4xl"> #RMHC2022</h1>
            {/*  className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-primary bg-white hover:bg-indigo-50 sm:w-auto" */}
            <a href="https://rmhc2022.eventbrite.ca/">
              <button className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-primary text-3xl bg-white hover:bg-indigo-50 w-auto">
                <p className="text-2xl">REGISTER NOW</p>
              </button>
            </a>
          </div>
        </main>
      </div>
    </div>
  );
}

export default HomeHeader;
