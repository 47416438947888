import React from "react";
import MMWelcomeCard from "../../components/MMWelcome/MMWelcomeCard";

function ContactUsForm() {
  return (
    <MMWelcomeCard
      backgroundColor="bg-white"
      contentArea={
        <div>
          <ContactUsFormContent />
        </div>
      }
    />
  );
}

function ContactUsFormContent() {
  return (
    <form action="https://usebasin.com/f/858596b027aa" method="POST">
      <div className="relative md:p-6">
        <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none">
          <div className="pt-8">
            <div>
              <h3 className="mt-2 text-3xl font-bold tracking-tight text-primary sm:text-4xl sm:tracking-tight">
                PLEASE FILL OUT THE FORM BELOW
              </h3>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-primary text-left"
                >
                  Name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="Name"
                    id="name"
                    autoComplete="given-name"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-primary text-left"
                >
                  Email
                </label>
                <div className="mt-1">
                  <input
                    type="email"
                    name="Email"
                    id="email"
                    autoComplete="given-name"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-primary text-left"
                >
                  Message
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="Message"
                    id="message"
                    autoComplete="given-name"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>

            <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 flex justify-center">
        <div className="pt-4">
          <button className="flex content-center justify-center px-8 py-3 border border-transparent text-base font-semi-bold rounded-lg text-white shadow-md bg-primary hover:opacity-75">
            SUBMIT
          </button>
        </div>
      </div>

           


          </div>
        </div>
      </div>
    </form>
  );
}

export default ContactUsForm;
