import React from "react";
import Spotify from "react-spotify-embed";
import Button from "../../components/UI/Button/Button";

function ProgramAndFAQs() {
  return (
    <div className="bg-white relative overflow-hidden">
      <div
        className="sm:h-full sm:w-full max-w-7xl sm:overflow-hidden"
        aria-hidden="true"
      ></div>

      <div className="relative">
        <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-20">
          <div className="text-center">

            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 flex justify-center">
                <a href="/schedule">
                <Button
                  href="/schedule"
                  text="VIEW OUR FULL 2022 PROGRAM"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary hover:bg-secondary-dark md:py-4 md:text-lg md:px-10"
                ></Button>
                </a>
                
              </div>

              <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 flex justify-center">
                <a href="/faqs">
                <Button
                  href="/faqs"
                  text="FAQs"
                  className="w-full flex items-center justify-center px-8 py-3 border border-teritiary-light hover:opacity-75 text-base font-medium rounded-md text-primary bg-white shadow hover:opacity-75 md:py-4 md:text-lg"
                >
                  FAQS
                </Button>{" "}
                </a>
                
              </div>
            </div>
           
            

          </div>
        </main>
      </div>
    
      <div className="sm:mt-0 sm:ml-3 pt-10 pb-10 px-40">
      <p className="text-2xl font-bold tracking-wide sm:text-3xl text-primary pb-10">
      Check out our RMHC 2022 playlist
          </p>
            <Spotify wide link="https://open.spotify.com/playlist/5jrCCoxHGcCsqf3ytbMUSQ?si=6f628d959aa04db5"/>
            </div>
    </div>
  );
}

export default ProgramAndFAQs;
