import React, { useEffect } from "react";
import OtherHeader from "../../components/UI/Headers/OtherHeader";
// import HeaderNew from "../../components/UI/HeaderNew";
import Footer from "../LandingPage/Footer";
import ConferenceHeader from "../../assets/images/conference/ConferenceHeader.jpeg";

import SpeakerOne from "../../assets/images/conference/Speakers_Small/SpeakerOne.jpg";
import SpeakerTwo from "../../assets/images/conference/Speakers_Small/SpeakerTwo.jpg";

import Cassandra from "../../assets/images/conference/Speakers_Small/Cassandra.jpg";
import Hirut from "../../assets/images/conference/Speakers_Small/Hirut.jpg";
import Stacia from "../../assets/images/conference/Speakers_Small/Stacia.jpg";

import Karline from "../../assets/images/conference/Speakers_Small/Karline.jpg";

import Manavi from "../../assets/images/conference/Speakers_Small/Manavi.jpg";
import Martha from "../../assets/images/conference/Speakers_Small/Martha.jpg";
import Kristin from "../../assets/images/conference/Speakers_Small/Kristin.jpg";
import Kearie from "../../assets/images/conference/Speakers_Small/Kearie.jpg";
import Sara from "../../assets/images/conference/Speakers_Small/Sara.jpg";
import Shani from "../../assets/images/conference/Speakers_Small/Shani.jpg";
import Lamptey from "../../assets/images/conference/Speakers_Small/Lamptey.jpg";
import Vedam from "../../assets/images/conference/Speakers_Small/Vedam.jpg";
import Cindy from "../../assets/images/conference/Speakers_Small/Cindy.jpg";
import Modupe from "../../assets/images/conference/Speakers_Small/Modupe.jpg";
import Keddone from "../../assets/images/conference/Speakers_Small/Keddone.jpg";
import BelowSpeakers from "../LandingPage/BelowSpeakers";
const schedule = [
  {
    startTime: "10:15 am",
    endTime: "11:00 am",
    title:
      "Keynote:  Battling Bias: Learning from the Lived Experience to Advance Black Maternal Care",
    speakers: [
      {
        name: "Kimberly Seals Allers",
        image: SpeakerOne,
      },
    ],
  },

  {
    startTime: "11:05 am",
    endTime: "11:50 am",
    title: "Keynote: Reclaiming and Rethinking Visibility",
    speakers: [
      {
        name: "Dr. Jackie Schleifer Taylor",
        image: SpeakerTwo,
      },
    ],
  },
  {
    startTime: "11:55 am",
    endTime: "12:15 pm",
    title: "Session: Who is Ocama?",
    speakerTitle: "Ocama Collective",
    speakers: [
      {
        name: "Cassandra Thompson",
        image: Cassandra,
      },
      {
        name: "Hirut Melaku",
        image: Hirut,
      },
      {
        name: "Stacia Stewart",
        image: Stacia,
      },
    ],
  },
];

const panelSpeakers = [
  {
    name: "Shani Robertson",
    image: Shani,
  },
  {
    name: "Dr. Cynthia Maxwell",
    image: Cindy,
  },
  {
    name: "Dr. Modupe Tunde-Byass",
    image: Modupe,
  },
  {
    name: "Kedonne Dias",
    image: Keddone,
  },
  {
    name: "Saraswathi Vedam",
    image: Vedam,
  },
  {
    name: "Dr. Na-Koshie Lamptey",
    image: Lamptey,
  },
];

const scheduleTwo = [
  {
    startTime: "2:20 pm",
    endTime: "2:35 pm",
    title: "Session:  Midwifery Services at the Non-Insured Walk In Clinic",
    speakers: [
      {
        name: "Manavi Handa",
        image: Manavi,
      },
    ],
  },

  {
    startTime: "2:40 pm",
    endTime: "2:55 pm",
    title: "Session:  Addressing the harms of perinatal incarceration",
    speakers: [
      {
        name: "Martha Paynter",
        image: Martha,
      },
    ],
  },
];

const scheduleThree = [
  {
    startTime: "3:20 pm",
    endTime: "3:35 pm",
    title:
      "Session: From Infancy to Adolescence – Black Motherhood and Advocacy",
    speakers: [
      {
        name: "Kearie Daniel",
        image: Kearie,
      },
    ],
  },

  {
    startTime: "3:40 pm",
    endTime: "4:10 pm",
    title:
      "Session: Exploring Intersections of Indigenous Innovation, Economic Resiliency and Health",
    speakers: [
      {
        name: "Sara Wolfe",
        image: Sara,
      },
    ],
  },
];

function FullProgram() {
  useEffect(() => {
    // should this be removed? theres gotta be a better way to do this. If not, I don't think its worth the messy code
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();
        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  });
  return (
    <div className="overflow-auto md:overflow-scroll ...">
      {/* {mommyMonitorAnimatedLogo} */}
      <OtherHeader
        title={"RACIALIZED MATERNAL HEALTH CONFERENCE"}
        description={"Conference Schedule"}
        headerImage={ConferenceHeader}
      />
      <p className="text-3xl font-bold tracking-wide sm:text-4xl text-primary pt-10">
        November 18<sup class="font-features sups">th</sup> 2022
      </p>
      <div className="px-4 sm:px-6 lg:px-8 pb-10">
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle px-4 md:px-6 lg:px-32">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-md rounded-md md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-100">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6"
                      >
                        SESSION
                      </th>
                      <th
                        scope="col"
                        className=" whitespace-nowrap px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                      >
                        START TIME
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                      >
                        END TIME
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white text-left">
                    {/* Welcome */}
                    <tr className="text-left bg-primary hover:bg-opacity-80">
                      <td className="px-3 py-4 text-md text-gray-900 font-bold">
                        <div className="text-white">Welcome/Introduction</div>
                      </td>

                      <td className=" px-3 py-4 text-md text-gray-900">
                        <div className="text-white">10:00 am</div>
                      </td>
                      <td className=" px-3 py-4 text-md text-gray-900">
                        <div className="text-white">10:15 am</div>
                      </td>
                    </tr>

                    {/* Rest */}
                    {schedule.map((item) => (
                      <tr className="text-left hover:bg-teritiary-light">
                        <td className="px-3 py-4 text-md text-gray-900">
                          <div className="text-gray-900 font-bold">
                            {item.title}
                          </div>

                          <div className="text-gray-900">
                            {item.speakerTitle}
                          </div>
                          <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                            {item.speakers.map((speaker) => (
                              <div className="flex items-center pt-4">
                                <div className="h-12 w-12 flex-shrink-0">
                                  <img
                                    className="h-12 w-12 rounded-full"
                                    src={speaker.image}
                                    alt=""
                                  />
                                </div>
                                <div className="ml-4">
                                  <div className="font-medium text-gray-900">
                                    {speaker.name}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </td>

                        <td className=" px-3 py-4 text-md text-gray-900">
                          <div className="text-gray-900">{item.startTime}</div>
                        </td>
                        <td className=" px-3 py-4 text-md text-gray-900">
                          <div className="text-gray-900">{item.endTime}</div>
                        </td>
                      </tr>
                    ))}

                    <tr className="text-left hover:bg-teritiary-light">
                      <td className="px-3 py-4 text-md text-gray-900">
                        <div className="text-gray-900 font-bold">
                          Oral Presentation- The Breathwork of Ar-Rahman: An
                          Islamic Vision of Birth Justice
                        </div>

                        <div className="flex items-center pt-4">
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">
                              Dr. Sarah Munawar
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-md text-gray-900">
                        <div className="whitespace-nowrap text-gray-900">
                          12:20 pm
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-md text-gray-900">
                        <div className="text-gray-900">12:30 pm</div>
                      </td>
                    </tr>

                    <tr className="text-left hover:bg-teritiary-light">
                      <td className="px-3 py-4 text-md text-gray-900">
                        <div className="text-gray-900 font-bold">
                          {" "}
                          Session: Hidden Figures in Canadian Midwifery
                        </div>

                        <div className="flex items-center pt-4">
                          <div className="h-12 w-12 flex-shrink-0">
                            <img
                              className="h-12 w-12 rounded-full"
                              src={Karline}
                              alt=""
                            />
                          </div>
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">
                              Karline Wilson Mitchell
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="px-3 py-4 text-md text-gray-900">
                        <div className="whitespace-nowrap text-gray-900">
                          12:35 pm
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-md text-gray-900">
                        <div className="text-gray-900">1:00 pm</div>
                      </td>
                    </tr>

                    <tr className="text-left hover:bg-teritiary-light">
                      <td className="px-3 py-4 text-md text-gray-900">
                        <div className="text-gray-900 font-bold">
                          {" "}
                          Panel: The future of perinatal and infant health in
                          Canada (* A discussion exploring what is being done
                          for maternal/perinatal health and women’s health in
                          Canada*)
                        </div>
                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                          {panelSpeakers.map((speaker) => (
                            <div className="flex items-center pt-4">
                              <div className="h-12 w-12 flex-shrink-0">
                                <img
                                  className="h-12 w-12 rounded-full"
                                  src={speaker.image}
                                  alt=""
                                />
                              </div>
                              <div className="ml-4">
                                <div className="font-medium text-gray-900">
                                  {speaker.name}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </td>

                      <td className=" px-3 py-4 text-md text-gray-900">
                        <div className="text-gray-900">1:15 pm</div>
                      </td>
                      <td className=" px-3 py-4 text-md text-gray-900">
                        <div className="text-900">2:15 pm</div>
                      </td>
                    </tr>

                    {/* <tr className="text-left hover:bg-teritiary-light">
                      <td className="px-3 py-4 text-md text-gray-900">
                        <div className="text-gray-900"></div>
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-md text-gray-900">
                        <div className="text-white">2:15 pm</div>
                      </td>
                      <td className=" px-3 py-4 text-md text-gray-900">
                        <div className="text-white">2:20 pm</div>
                      </td>
                    </tr> */}

                    {scheduleTwo.map((item) => (
                      <tr className="text-left hover:bg-teritiary-light">
                        <td className="px-3 py-4 text-md text-gray-900">
                          <div className="text-gray-900 font-bold font-bold">
                            {item.title}
                          </div>

                          <div className="text-gray-900">
                            {item.speakerTitle}
                          </div>

                          {item.speakers.map((speaker) => (
                            <div className="flex items-center pt-4">
                              <div className="h-12 w-12 flex-shrink-0">
                                <img
                                  className="h-12 w-12 rounded-full"
                                  src={speaker.image}
                                  alt=""
                                />
                              </div>
                              <div className="ml-4">
                                <div className="font-medium text-gray-900">
                                  {speaker.name}
                                </div>
                              </div>
                            </div>
                          ))}
                        </td>

                        <td className=" px-3 py-4 text-md text-gray-900">
                          <div className="text-gray-900">{item.startTime}</div>
                        </td>
                        <td className=" px-3 py-4 text-md text-gray-900">
                          <div className="text-gray-900">{item.endTime}</div>
                        </td>
                      </tr>
                    ))}

                    <tr className="text-left hover:bg-teritiary-light">
                      <td className="px-3 py-4 text-md text-gray-900 font-bold">
                        <div className="text-gray-900">
                          Oral presentation - Black Mothers'
                          Childbirth Experiences
                        </div>
                        <div className="flex items-center pt-4">
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">
                            Cheyenne Scarlett
                            </div>
                          </div>
                        </div>
                      </td>
                      

                      <td className="whitespace-nowrap px-3 py-4 text-md text-gray-900">
                        <div className="text-gray-900">3:00 pm</div>
                      </td>
                      <td className=" px-3 py-4 text-md text-gray-900">
                        <div className="text-gray-900">3:10 pm</div>
                      </td>
                    </tr>

                    {scheduleThree.map((item) => (
                      <tr className="text-left hover:bg-teritiary-light">
                        <td className="px-3 py-4 text-md text-gray-900">
                          <div className="text-gray-900 font-bold">
                            {item.title}
                          </div>

                          <div className="text-gray-900">
                            {item.speakerTitle}
                          </div>

                          {item.speakers.map((speaker) => (
                            <div className="flex items-center pt-4">
                              <div className="h-12 w-12 flex-shrink-0">
                                <img
                                  className="h-12 w-12 rounded-full"
                                  src={speaker.image}
                                  alt=""
                                />
                              </div>
                              <div className="ml-4">
                                <div className="font-medium text-gray-900">
                                  {speaker.name}
                                </div>
                              </div>
                            </div>
                          ))}
                        </td>

                        <td className=" px-3 py-4 text-md text-gray-900">
                          <div className="text-gray-900">{item.startTime}</div>
                        </td>
                        <td className=" px-3 py-4 text-md text-gray-900">
                          <div className="text-gray-900">{item.endTime}</div>
                        </td>
                      </tr>
                    ))}

                    <tr className="text-left bg-primary hover:bg-opacity-80">
                      <td className="px-3 py-4 text-md text-gray-900 font-bold">
                        <div className="text-white">End</div>
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-md text-gray-900">
                        <div className="text-white">4:10 pm</div>
                      </td>
                      <td className=" px-3 py-4 text-md text-gray-900">
                        <div className="text-white">4:20 pm</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-primary p-10">
        <h1 className="space-y-5 pt-4 ">
          <span className="block mt-3 max-w-md mx-auto text-base text-white sm:text-3xl md:mt-5 text-3xl md:text-3xl  pb-4">
            RMHC2022 will include virtual speaker sessions and in-person speaker
            events for all participants.
          </span>

          {/* <span className="blockmt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          Our speakers will be shared soon.
        </span> */}
        </h1>

        <a href="https://rmhc2022.eventbrite.ca">
          <button className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-primary bg-teritiary hover:opacity-75 w-auto tracking-wide">
            REGISTER NOW
          </button>
        </a>
      </div>

      <Footer />
    </div>
  );
}

export default FullProgram;
