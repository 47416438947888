import React from "react";
import MMWelcomeCard from "../../components/MMWelcome/MMWelcomeCard";

function AboutVirtual() {
  return (
    <MMWelcomeCard
      backgroundColor="bg-white"
      contentArea={
        <div>
          <AboutMedia />
        </div>
      }
    />
  );
}

function AboutMedia() {
  return (
    <div className="md:bg-white md:p-6">
      <div className="text-primary lg:max-w-none">
        <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">
          Important
        </h1>
        <p className="text-left text-xl  max-w-3xl">
          All media interested in covering the conference must register. To
          register send an email to conference@mommymonitor.ca with the
          following information:
        </p>
        <ol className="list-disc px-10 text-left">
          <li>Name</li>
          <li>Media outlet</li>
          <li>Photocopy of press badge</li>
          <li>Links to articles</li>
        </ol>
      </div>
    </div>
  );
}

export default AboutVirtual;
