import React from "react";

export default function Button({ clicked, disabled, text }) {
  return (
        <div className="pt-4">
<button
          onClick={clicked}
          className="flex content-center justify-center px-8 py-3 border border-transparent text-base font-semi-bold rounded-lg text-white shadow-md bg-primary hover:bg-primary-light"
          disabled={disabled}
        >
          {text}
        </button>
        </div>
        
    
  );
}
