import React from "react";
import MMWelcomeCard from "../../components/MMWelcome/MMWelcomeCard";

function AboutSponsors() {
  return (
    <MMWelcomeCard
      backgroundColor="bg-white"
      contentArea={
        <div>
          <AboutSponsorsContent />
        </div>
      }
    />
  );
}

function AboutSponsorsContent() {
  return (
    <div className="relative md:bg-white md:p-6">
      <div className="">
        <div className="prose prose-indigo prose-lg text-primary lg:max-w-none">
        <div>
              <h3 className="mt-2 text-3xl font-bold tracking-wide text-primary sm:text-4xl sm:tracking-tight">
              SPONSORS MAKE IT HAPPEN
              </h3>
            </div>
          <p className="mt-8 text-2xl text-primary leading-8 pb-10">
          We appreciate all of our sponsors and thank them for their support and dedication to Racialized Maternal Health. If you are interested in being a sponsor for the 2022 conference email us at 
         <a href="mailto: conference@mommymonitor.ca" className="text-blue-700 underline"> conference@mommymonitor.ca</a> to receive the sponsorship package.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutSponsors;
