import React from "react";
function MMWelcomeCard({ contentArea, backgroundColor }) {
  return (
    <div className={backgroundColor}>
      <div className="relative overflow-hidden w-screen">
        <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
          <div aria-hidden="true" className="absolute inset-x-0 top-0 h-48" />
          <div>{contentArea}</div>
        </div>
      </div>
    </div>
  );
}

export default MMWelcomeCard;
