import React from "react";
import MMWelcomeCard from "../../components/MMWelcome/MMWelcomeCard";
import SpeakerOne from "../../assets/images/conference/Speakers/SpeakerOne.jpg";
import SpeakerTwo from "../../assets/images/conference/Speakers/SpeakerTwo.jpg";
import Cassandra from "../../assets/images/conference/Speakers/Cassandra.jpg";
import Hirut from "../../assets/images/conference/Speakers/Hirut.jpg";
import Stacia from "../../assets/images/conference/Speakers/Stacia.jpg";
import Karline from "../../assets/images/conference/Speakers/Karline.jpg";
import Manavi from "../../assets/images/conference/Speakers/Manavi.jpg";
import Martha from "../../assets/images/conference/Speakers/Martha.jpg";
import Kristin from "../../assets/images/conference/Speakers/Kristin.jpg";
import Kearie from "../../assets/images/conference/Speakers/Kearie.jpg";

const speakers = [
  {
    name: "Kimberly Seals Allers",
    title:
      "Maternal Health Tech Founder - The Irth App, Award-Winning Writer & Author, and Host of the Birthright Podcast",
    imageUrl: SpeakerOne,
    description:
      "Kimberly Seals Allers is an award-winning journalist, five-time author, international speaker, strategist, and advocate for maternal & infant health. A former senior editor at ESSENCE and writer at FORTUNE magazine, Kimberly is a leading voice on the racial and socio-cultural complexities of birth, breastfeeding, and motherhood. She is the founder of Irth, a new 'Yelp-like' app for Black and brown parents to address bias and racism in maternity and infant care. Kimberly also created Birthright, a podcast about joy and healing in Black birth that centers on positive Black birth stories as a tool in the fight for birth justice and reverses the narrative of negative statistics common in mainstream media coverage of Black maternal health. Learn more at KimberlySealsAllers.com. Follow her @iamKSealsAllers on Facebook, Twitter, and Instagram. ",
  },

  {
    name: "Dr. Jackie Schleifer Taylor, PT, PhD, CHE",
    title: "President and CEO, London Health Sciences Centre",
    description:
      "As President and CEO of one of Ontario’s largest birthing centres and as a mother, Dr. Jackie Schleifer Taylor applies the dual lens of professional and speakeral experience to improving racialized maternal health. With more than 25 years of leadership experience, she consistently shows an unwavering commitment to quality, inclusion and accountability. She is a registered physiotherapist deeply rooted in her clinical and academic background, and she has a proven track record in system innovation. She has served on various boards and think tanks, including serving as Chair of the Provincial Council for Maternal and Child Health.",
    imageUrl: SpeakerTwo,
  },
];

const speakersTwo = [
  {
    name: "Cassandra Thompson",
    title: "President - Ocama Collective",
    imageUrl: Cassandra,
    description:
      "Cassandra is the President of Ocama Collective and has been an actively practicing doula for over 5 years. She is currently focused on termination/abortion support and supporting parents in sex work. She is a lifetime student of plant medicine and has had the blessing of receiving informal training from community aunties throughout her life.",
  },

  {
    name: "Hirut Melaku",
    title: "Director of Community Engagement - Ocama Collective ",
    description:
      "Hirut Melaku is an emerging scholar and healthcare worker (lactation consultant, birth companion), concerned with racial inequity, sexual violence and LGBTQ2S+ issues. The investigative and reporting work that she has done in the last 20 years has been used by many, including the United Nations, to determine humanitarian aid, and by local players to develop initiatives, programs, and interventions in the area of mental health and gender-based violence.  Transformative justice, as a framework, guides all aspects of her work including her current role as a facilitator and advisor to organizations who are invested in working through difficult conversations. She contributes to repairing the world as a healer, nurturer, and as a speaker of truth. Hirut credits her ancestors, distinctive background, and identities (Beta Israel, queer, mother of a gifted child who is differently-abled) for keeping her grounded and connected. She is a co-founder of the Third Eye Collective, a survivor-led organization for Black women who have experienced violence.  (www.hirut.org, www.thirdeyemontreal.com). She is currently a PhD student in Critical Disability Studies at York University. The final outcome of her Master's in Environmental Studies, rooted in investigating  reproductive injustice in Canada, is the documentary 'Birthing while Black during COVID-19™'. These short films consist of interviews with Black birth workers, and Black mothers who were pregnant or gave birth during the pandemic in Canada. She currently sits on the board of Ocama Collective, a community-directed group of racialized birth workers living and working in Tkaronto (Toronto), who are dedicated to the reclamation of traditional and holistic childbearing and birthing practices, amongst queer, trans, Indigenous, Black and racialized people.",
    imageUrl: Hirut,
  },
  {
    name: "Stacia Stewart (She/her)",
    title: "Director of Development- Ocama Collective",
    imageUrl: Stacia,
    description:
      "Stacia Stewart is a parent, auntie to many, a community health educator and advocate. Over the last 25 years Stacia has worked and created community wellness programs in different communities across Turtle Island / Canada  in the areas of anti-violence work, infant and child development & care, reproductive justice and perinatal health, doula care, LGBTQ health promotion, and newcomer settlement services. She is a founding member of two doula organizations focused on education, professional support, doula care and advocacy within Black, Indigenous, and 2SLGBTQ+ communities of colour. Stacia is currently working while completing a graduate degree in educational leadership and lives in Tkaronto / Toronto with her 3 teenaged children and their badly behaved cat.",
  },
  {
    name: "Prof. Karline Wilson-Mitchell",
    title:
      "DNP, CNM, MSN, FACNM, Co-PI Canadian Midwives of Colour (CMOC) Project,Associate Professor, Toronto Metropolitan University, Director, Midwifery Education Program",
    imageUrl: Karline,
    description:
      "Karline Wilson-Mitchell is passionate about reproductive justice that informs midwifery education, practice and global partnerships. Since 1992, Karline’s clinical work grew from the U.S. (urban and rural) to Canada (Ontario, remote Quebec) and then to midwifery education and leadership building in the Global South (Jamaica, Tanzania, Zambia, Burundi, South Sudan). Her scholarship explores the skills and infrastructure necessary to diversify the midwifery workforce, to explore strategies that facilitate equitable and inclusive work environments for midwives and vulnerable populations. Her goal is to promote resilience and sagacity in vulnerable midwifery students.  Karline has been teaching in the Ryerson Midwifery Education Program since 2008.",
  },

  {
    name: "Manavi Handa (She/her)",
    title:
      "RM, MHSc Associate Professor, Midwifery Education Program, Toronto Metropolitan University West End Midwives and Non-Insured Walk-In Clinic, AACHC",
    description:
      "Manavi Handa is a registered midwife practicing at West End Midwives and the Non-Insured Walk-In Clinic. She is an Associate Professor at Toronto Metropolitan University. For the past two decades, Manavi has focused her clinical practice, research and social justice activism on serving marginalized populations in Toronto. In particular, Manavi has done extensive work with people who are uninsured/non-status and newly arrived refugees. Her work gives her insight into the lived realities of racialized clients facing barriers in perinatal health care. Her research was instrumental in expanded ministry of health funding for uninsured clients in midwifery care in 2015.",
    imageUrl: Manavi,
  },
  {
    name: "Dr. Martha Paynter",
    title:
      "Assitant Professor, University of New Brunswick, Founder of Wellness Within",
    imageUrl: Martha,
    description:
      "Dr. Martha Paynter is the author of Abortion to Abolition: Reproductive Health and Justice in Canada. She is an Assistant Professor at the University of New Brunswick, where her research examines the intersection of reproductive health and the justice system. She is a faculty member of the Contraception and Abortion Research Team based out of the Faculty of Medicine at the University of British Columbia, and with the Health Law Institute at the Schulich School of Law at Dalhousie University. The founder of Wellness Within: An Organization for Health and Justice, Dr. Paynter received the Senate of Canada 150 Anniversary Medal for her volunteer service to the country. Her clinical practice focuses on abortion and reproductive care.",
  },
  {
    name: "Kristin Taylor",
    title: "Director of Provincial Programs, Ministry of Health",
    imageUrl: Kristin,
    description:
      "Kristin Taylor is the director of the Provincial Programs Branch at the Ministry of Health. She joined the Ministry in 2008 and throughout her career has focused on strategic policy and program management. Since 2010, Kristin has had many perinatal health files within her portfolio including Newborn Screening Ontario, Prenatal Screening Ontario, BORN Ontario, Pregnancy and Infant Loss (PAIL) Network, Provincial Council for Maternal and Child Health (PCMCH) and more. She has improved access to health services and strengthened coordination and oversight of various provincially funded programs. In addition to perinatal health files, Kristin also leads an extensive portfolio that includes over $7.5 billion in healthcare funding for programs such as cancer, transplant, HIV, Hepatitis C, Naloxone, cardiac, neuroservices, sexual violence, wait times funding and most recently surgical recovery.",
  },
  {
    name: "Kearie Daniel",
    title: "Co-Founder and Executive Director of Parents of Black Children",
    imageUrl: Kearie,
    description:
      "Kearie Daniel is  the co-founder and Executive Director of Parents of Black Children.  Kearie has over 20 years as a Senior leader, community advocate, communications strategist and equity leader. Kearie is also the principal consultant at KAMA Communications, an Equity, Inclusion and Communications consulting firm, one of the few Communications Consulting firms in Toronto to anchor the frameworks of anti-oppression and anti-Black racism in all work. Kearie has also led systemic change across multiple systems in the province of Ontario including the education and child welfare systems, where she led communications and community engagement for Phase II of the One Vision One Voice community initiative to address the overrepresentation of Black children in the child welfare system, at the Ontario Association of Children's Aid Societies. In 2017 Kearie created the Podcast and Blog, Woke Mommy Chatter, a space to combat the invisibilizing of the Black motherhood experience and  to shed light on the Black parenting journey. Kearie writes about the Black motherhood experience for a variety of publications, including Today's Parents, CBC Parent, Flare and Chatelaine.  Kearie has delivered international anti-Black racism and equity trainings to parents looking to build and live anti-racist lives. Kearie holds a BA(Hons) in Mass Communication and a Masters Degree in Broadcast Journalism. Kearie began her career as a Journalist working as a freelance associate producer and assignment editor for NBC News, London Bureau.",
  },
];

function Speakers() {
  return (
    <MMWelcomeCard
      backgroundColor="bg-white"
      contentArea={
        <div>
          <SpeakersContent />
        </div>
      }
    />
  );
}

function SpeakersContent() {
  return (
    <div className="px-10 py-10">
      <p className="text-3xl font-bold tracking-wide sm:text-4xl text-primary">
        OUR KEYNOTE SPEAKERS
      </p>{" "}
      <ul
        role="list"
        className="space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:grid-cols-2 lg:gap-8"
      >
        {speakers.map((person) => (
          <li
            key={person.name}
            className="rounded-lg py-10 px-6 text-center xl:px-10 xl:text-left text-center"
          >
            <div className="space-y-6 xl:space-y-10">
              <img
                className="mx-auto h-60 w-60 rounded-full xl:h-80 xl:w-80"
                src={person.imageUrl}
                alt=""
              />
              <div className="space-y-2 text-center">
                <div className="space-y-1 text-lg font-medium ">
                  <h3 className="text-primary">{person.name}</h3>
                  <p className="text-indigo-800">{person.title}</p>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
      {/* <h1 className="tracking-tight text-4xl pb-14 pt-10">Ocama Collective</h1>
      <ul
        role="list"
        className="pb-10 mx-auto grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-2 md:gap-x-4 lg:max-w-5xl lg:gap-x-4 lg:gap-y-12 xl:grid-cols-3"
      >
        {ocamaCollective.map((person) => (
          <li key={person.name}>
            <div className="space-y-4">
              <img
                className="mx-auto rounded-full h-36 w-36"
                src={person.imageUrl}
                alt=""
              />
              <div className="space-y-2">
                <div className="text-xs font-medium lg:text-sm">
                  <h3>{person.name}</h3>
                  <p className="text-indigo-600">{person.title}</p>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul> */}
      <a
        href="/speakers"
        className="text-xl font-medium text-gray-500 hover:text-gray-600 underline"
      >
        <button className="mt-8 w-full inline-flex items-center justify-center px-6 py-4 border border-transparent text-base font-medium rounded-xl text-white bg-primary hover:opacity-75 w-auto text-underline">
          <h1 className="text-3xl">VIEW ALL SPEAKERS</h1>
        </button>
      </a>
      <ul
        role="list"
        className="pt-10 mx-auto grid grid-cols-2 gap-x-6 gap-y-8 sm:grid-cols-4 md:gap-x-6 lg:max-w-5xl lg:gap-x-8 lg:gap-y-12 xl:grid-cols-4"
      >
        {speakersTwo.map((person) => (
          <li key={person.name}>
            <div className="space-y-4">
              <img
                className="mx-auto  rounded-full h-28 w-28 lg:h-36 lg:w-36 md:h-28 md:w-28"
                src={person.imageUrl}
                alt=""
              />
              <div className="space-y-2">
                <div className="text-xs font-medium lg:text-sm">
                  <h3>{person.name}</h3>
                  <p className="text-indigo-600">{person.role}</p>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Speakers;
