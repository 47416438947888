import React from "react";
import MMWelcomeCard from "../../components/MMWelcome/MMWelcomeCard";

function AboutInfo() {
  return (
    <MMWelcomeCard
      backgroundColor="bg-white"
      contentArea={
        <div>
          <AboutInfoContent />
        </div>
      }
    />
  );
}

function AboutInfoContent() {
  return (
    <div>
      <div className="md:bg-white md:p-6">
        <div className="text-primary lg:max-w-none">
          <h3 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:tracking-tight">
            THE CONFERENCE
          </h3>
          <p className="mt-8 text-2xl text-primary leading-8 pb-10">
            The Racialized Maternal Health Conference is a forum where
            professionals, students, experts, researchers, policy-makers and
            other stakeholders will come together to pursue efforts to improve
            racialized maternal-newborn health and practices in Canada by
            promoting best practices, cultural competency and advocacy for
            racialized maternal health issues, research and policies.
          </p>

          <h3 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:tracking-tight">
            WHO SHOULD ATTEND
          </h3>
          <p className="mt-8 text-2xl text-primary leading-8 pb-10">
            The Racialized Maternal Health Conference is a meeting place for a
            wide range of stakeholders, organizations, academics, researchers,
            communities, students and policy-makers. In addition to, anyone who
            is interested in understanding the impact, burden and knowledge
            practices for racialized maternal health care in Canada, should be
            attending this conference.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutInfo;
