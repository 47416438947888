import React, { useEffect } from "react";
import OtherHeader from "../../components/UI/Headers/OtherHeader";
import Footer from "../LandingPage/Footer";
// import FAQsItems from "./FAQsItems";
import VirtualHeader from "../../assets/images/conference/VirtualHeader.png";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import FAQsItems from "./FAQsItems";


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function FAQs() {
  useEffect(() => {
    // should this be removed? theres gotta be a better way to do this. If not, I don't think its worth the messy code
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();
        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  });
  return (
    <>
      <div className="overflow-auto md:overflow-scroll ... ">
        <OtherHeader
        title={"Frequently asked questions"}
        headerImage={VirtualHeader}
        />

        <FAQsItems />
     
        <Footer/>

      </div>
    </>
  );
}

export default FAQs;
