import React from "react";
import MMWelcomeCard from "../../components/MMWelcome/MMWelcomeCard";
import Vendor from "../../assets/images/conference/vendor.jpeg";
import Partner from "../../assets/images/conference/partner.jpeg";
import Present from "../../assets/images/conference/present.jpeg";
import Abstract from "../../assets/images/conference/submit_abstract.jpeg";
import Dialogue from "../../assets/images/conference/dialogue.jpeg";
import Social from "../../assets/images/conference/social.jpeg";

const contentOne = [
  {
    imageUrl: Vendor,
    heading: "Become a Vendor",
    linkText: "/submit-vendor",
  },
  {
    imageUrl: Partner,
    heading: "Become a Partner",
    linkText: "/donors-partners",
  },
  {
    imageUrl: Abstract,
    heading: "Submit an Abstract",
    linkText: "/",
  },
  {
    imageUrl: Dialogue,
    heading: "Find out more about our RMHC Birth Dialogues",
    linkText: "/",
  },
];

const contentTwo = [
  {
    imageUrl: Present,
    heading: "Present your technology at our showcase",
    linkText: "/innovation-showcase",
    description: "We want to share new and exciting technologies/products that are relevant to birth, women/infant health, parenting, reproductive health, or perinatal health."
  },
  
  {
    imageUrl: Social,
    heading: "RMHC Social Night",
    linkText: "/",
    description: "Join us for a night of celebration, networking, and collaboration."
  },
];

function GetInvolved() {
  return (
    <MMWelcomeCard
      backgroundColor="bg-primary"
      contentArea={
        <div>
          <GetInvolvedContent />
        </div>
      }
    />
  );
}

const GetInvolvedContent = () => {
  return (
      <div className="mx-auto max-w-md text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:p-20">
     <p className="text-3xl font-bold tracking-wide sm:text-4xl text-white">
            GET INVOLVED!
          </p>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 pt-16">
          {contentOne.map((item) => (
            <div className="relative overflow-hidden rounded-lg shadow-lg cursor-pointer bg-secondary-light">
              <a href={item.linkText}>
                <img
                  className="object-cover w-full h-48"
                  src={item.imageUrl}
                  alt=""
                />
                <div className="absolute inset-0 bg-primary-light opacity-75 mix-blend-multiply" />

                <div className="absolute inset-x-10 inset-y-16">
                  <h4 className="text-xl tracking-tight text-primary text-shadow">
                    <h4 className="text-2xl tracking-tight text-teritiary">
                      {item.heading}
                    </h4>
                  </h4>
                </div>
              </a>
            </div>
          ))}
        </div>

        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 pt-16">
          {contentTwo.map((item) => (
            <div className="relative overflow-hidden rounded-lg shadow-lg cursor-pointer">
              <a href={item.linkText}>
                <img
                  className="object-cover w-full h-64 sm:h-200"
                  src={item.imageUrl}
                  alt=""
                />
                <div className="absolute inset-0 bg-teritiary-dark  mix-blend-multiply" />

                <div className="absolute md:inset-x-0 :inset-x-10 inset-y-16">
                  <h4 className="text-2xl tracking-tight sm:text-md text-teritiary">
                    {item.heading}
                  </h4>
                  <p className="p-4 lg:text-lg md:text-xs text-teritiary">{item.description}</p>
                </div>
              </a>
            </div>
          ))}
        </div>

        <div className="relative">
          <div className="sm:text-center pt-10">
            <h2 className="text-3xl text-teritiary tracking-tight sm:text-4xl sm:tracking-tight">
              Subscribe to our newsletter
            </h2>
          </div>
          <form action="https://usebasin.com/f/8c9df389200f" method="POST" className="mt-12 sm:mx-auto sm:max-w-lg sm:flex">
            <div className="min-w-0 flex-1">
              <label htmlFor="email" className="sr-only">
                Email address
              </label>
              <input
                id="email"
                type="email"
                name="Email"
                className="block w-full border rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                placeholder="Enter your email"
              ></input>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-3">
              <button className="tracking-wide block w-full rounded-md border border-transparent px-5 py-3 bg-white text-base font-medium text-primary shadow hover:opacity-75 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:px-10">
                SUBSCRIBE
              </button>
            </div>
          </form>
        </div>
      </div>
  );
};

export default GetInvolved;
