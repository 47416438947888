import React, { useEffect } from "react";
import OtherHeader from "../../components/UI/Headers/OtherHeader";
import Footer from "../LandingPage/Footer";
import AboutVendor from "./AboutVendor";
import VendorForm from "./VendorForm";
import VendorImage from "../../assets/images/conference/vendor.png";

function SubmitVendor() {
  useEffect(() => {
    // should this be removed? theres gotta be a better way to do this. If not, I don't think its worth the messy code
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();
        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  });
  return (
    <>
      <div className="overflow-auto md:overflow-scroll ...">
        <OtherHeader
        title={"2022 Conference Vendors"}
        description={"Submissions now open"}
        headerImage={VendorImage}
        />
        
        <AboutVendor />
        <VendorForm />
        <Footer/>

      </div>
    </>
  );
}

export default SubmitVendor;
