import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import React from "react";
import MMWelcomeCard from "../../components/MMWelcome/MMWelcomeCard";

function FAQsItems() {
  return (
    <MMWelcomeCard
      backgroundColor="bg-white"
      contentArea={
        <div>
          <FAQsItemsContent />
        </div>
      }
    />
  );
}

const faqs = [
  {
    id: 1,
    question: "When is the conference? ",
    answer:
      "November 18, 2022",
  },
  {
    id: 2,
    question: "Where is the conference located?",
    answer:
      "The conference is virtual.",
  },
  {
    id: 3,
    question: "Will there be an evening event?",
    answer:
      "Yes, there will be a separate in person event on another date.",
  },
  {
    id: 4,
    question: "What is the new date of the social event?",
    answer:
      "TBA",
  },
  {
    id: 5,
    question: "Does my ticket include the conference and the social event?",
    answer:
      "Each individual ticket provides access to the virtual conference and the social event",
  },
  {
    id: 6,
    question: "If I purchased a ticket for the last conference date that was postponed, can I use it for this conference? ",
    answer:
      "Yes, all tickets purchased for the 2020 conference that was postponed can be used for the 2022 conference",
  },
  {
    id: 7,
    question: "How can I get a refund for my ticket? ",
    answer:
      "For a refund email us at conference@mommymonitor.ca",
  },
  {
    id: 8,
    question: "I have purchased a ticket and want to know how I will access the conference?",
    answer:
      "An email will be sent to all attendees by November 16, 2022, with a link to the platform where the conference will be held.",
  },

  // More questions...
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


function FAQsItemsContent() {
  return (
    <div className="relative md:bg-white">
      <div className="">
        <div className="prose prose-indigo prose-lg text-primary lg:max-w-none">
          <div className="mt-12">
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-large text-gray-900 font-bold">{faq.question}</span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-700 text-left">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQsItems;
