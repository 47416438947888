import React from "react";
import MMWelcomeCard from "../../components/MMWelcome/MMWelcomeCard";
import Button from "../../components/UI/Button/Button";
import Research from "../../assets/images/conference/research.jpeg";
import Learn from "../../assets/images/conference/learn.jpeg";
import Collaborate from "../../assets/images/conference/collaborate_two.jpeg";


const content = {
  headerOne: "Learn and engage with relevant topics during plenary sessions.",
  paragraphOne:
    "Keynote speeches and panel discussions focusing on vital topics and issues in racialized peri-natal healthcare.",
  imageOne: Learn,
  buttonTextOne: "REGISTER NOW",

  headerTwo:
    "Survey recent academic research with oral and poster presentations.",
  paragraphTwo:
    "Academics, researchers, and students share new knowledge in the field of racialized perinatal health care.",
  imageTwo: Research,
  buttonTextTwo: "ABSTRACT SUBMISSIONS CLOSED",

  headerThree:
    "Collaborate and grow through workshops and networking events.",
  paragraphThree:
    "We want to spark collaborations and give people a chance to get hands on with the knowledge they're learning at the RMHC. Attend our workshops and networking events to meet peers and begin working together!",
  imageThree: Collaborate,
  buttonTextThree: "VIEW OUR FULL 2022 PROGRAM",

  backgroundColor: "bg-teritiary",
};
function MoreInformation() {
  return (
    <MMWelcomeCard
      backgroundColor="bg-white"
      contentArea={
        <div>
          <MoreInformationContent />
        </div>
      }
    />
  );
}

function MoreInformationContent() {
  return (
    <div>
      <div className="divide-y divide-gray-300">
        <div className="">
          <div className="lg:mx-auto lg:max-w-7xl lg:pr-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl pt-6 mx-auto sm:px-6 lg:py-4 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
              
              <div className="">
                <h2 className="text-2xl tracking-tight">{content.headerOne}</h2>
                <p className="mt-4 text-lg">{content.paragraphOne}</p>
              </div>
              <div className="mt-3 pt-4 rounded-md sm:mt-0 flex justify-center">
                <a href="https://rmhc2022.eventbrite.ca">
                <Button
                  
                  text={content.buttonTextOne}
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary hover:bg-secondary-dark md:py-4 md:text-lg md:px-10"
                ></Button>
                </a>
                
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0">
              <div className="lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  className="w-full lg:max-w-none rounded-lg"
                  src={content.imageOne}
                  alt={content.headerOne}
                />
              </div>
            </div>
          </div>
        </div>
        

        <div className="relative mt-6 pb-6 pt-6">
          <div className="lg:mx-auto lg:max-w-7xl lg:pl-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl pt-6  mx-auto sm:px-6 lg:py-4 lg:max-w-none lg:mx-0 lg:px-0">
              <div className="">
                <h2 className="text-2xl tracking-tight">{content.headerTwo}</h2>
                <p className="mt-4 text-lg ">{content.paragraphTwo}</p>
              </div>
              <div className="mt-3 pt-4 rounded-md  sm:mt-0 flex justify-center">
                <button
                  href="/"
                  text={content.buttonTextTwo}
                  className="flex content-center justify-center px-8 py-3 border border-transparent text-base font-semi-bold rounded-lg text-white shadow-md bg-primary"
                >{content.buttonTextTwo}</button>                
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0">
              <div className="lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  className="w-full lg:max-w-none rounded-lg"
                  src={content.imageTwo}
                  alt={content.headerTwo}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 pb-6 pt-6">
          <div className="lg:mx-auto lg:max-w-7xl lg:pr-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl pt-6 mx-auto sm:px-6 lg:py-4 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
              <div className="">
                <h2 className="text-2xl tracking-tight">
                  {content.headerThree}
                </h2>
                <p className="mt-4 text-lg">{content.paragraphThree}</p>
              </div>
              <div className="mt-3 pt-4 rounded-md sm:mt-0 flex justify-center">
                <a href="/schedule">
                <Button
                  href="/schedule"
                  text={content.buttonTextThree}
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary-light md:py-4 md:text-lg md:px-10"
                ></Button>
                </a>
                
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0">
              <div className="lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  className="w-full lg:max-w-none rounded-lg"
                  src={content.imageThree}
                  alt={content.headerThree}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MoreInformation;
