import React from "react";
import Spotify from "react-spotify-embed";
import MMWelcomeCard from "../../components/MMWelcome/MMWelcomeCard";
import CountDownTimer from "./CountdownTimer";
import InPersonConferencePhoto from "../../assets/images/conference/InPersonConferencePhoto.png";

const hoursMinSecs = { hours: 1, minutes: 20, seconds: 40 };

function AboutConference() {
  return (
    <MMWelcomeCard
      backgroundColor="bg-white"
      contentArea={
        <div>
          <AboutConferenceContent />
        </div>
      }
    />
  );
}

function AboutConferenceContent() {
  return (
    <div>
      <div className="mx-auto object-center">
        <img
          className="mx-auto h-3/4 w-3/4 object-contain object-center"
          src={InPersonConferencePhoto}
          alt=""
        />
      </div>
      <div className="bg-white">
        <div className="object-center mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8 lg:py-6">
          <div className="md:ml-auto md:pl-10 space-y-4">
            {/* <p className="text-3xl font-bold tracking-wide sm:text-4xl text-primary">
            RMHC SOCIAL NIGHT
          </p> */}
            <p className="mt-3 text-lg text-gray-500">
              We are pleased to share that the evening event portion of last
              year’s conference is taking place on <b>February 3, 2023</b> at{" "}
              <b>The Globe and Mail Centre in Toronto.</b> If you are a 2022
              RMHC ticket holder, your ticket to this special event is free. A
              special code has been sent to all conference ticket holders to
              reserve your ticket. Limited tickets are also available for
              purchase.
            </p>

            <a href="https://www.eventbrite.ca/e/the-transformative-power-of-birth-tickets-518783755177">
              <button className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary hover:opacity-75 w-auto">
                GET YOUR TICKET HERE
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutConference;
