import React from "react";

function CountdownTimer() {
  const [[days, hrs, mins, secs], setTime] = React.useState([0, 0, 0, 0]);
  const targetDate = new Date(2022, 10, 18, 10, 0, 0);

  const tick = () => {
    const now = new Date().getTime();
    const distance = (targetDate.getTime() - now) / 1000;

    console.log(targetDate);
    console.log(now, distance);

    if (distance <= 0) {
      reset();
    } else {

      const _days = Math.floor(distance / (60 * 60 * 24));
      const _hrs = Math.floor((distance % (60 * 60 * 24)) / (60 * 60));
      const _mins = Math.floor((distance % (60 * 60)) / 60);
      const _secs = Math.floor(distance % 60);

      setTime([_days, _hrs, _mins, _secs]);
    }
  };

  const reset = () => setTime([0, 0, 0, 0]);

  React.useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  });

  return (
    <div className="flex justify-center">
    <div className="w-96">
      <div className="grid grid-cols-4 gap-3 text-white">
        <div className="col-span-1 flex justify-center bg-secondary-light py-4 px-4 rounded-lg">
          <p className="text-6xl">{`${days.toString()}`}</p>
        </div>
        <div className="col-span-1 flex justify-center bg-secondary-light py-4 px-4 rounded-lg">
          <p className="text-6xl">{hrs.toString().padStart(2, "0")}</p>
        </div>
        <div className="col-span-1 flex justify-center bg-secondary-light py-4 px-4 rounded-lg">
          <p className="text-6xl">{mins.toString().padStart(2, "0")}</p>
        </div>
        <div className="col-span-1 flex justify-center bg-secondary-light py-4 px-4 rounded-lg">
          <p className="text-6xl">{secs.toString().padStart(2, "0")}</p>
        </div>
      </div>

      <div className="grid grid-cols-4 gap-3 pb-10 text-teritiary-light">
        <div className="col-span-1 flex justify-center">
          <p className="text-md">Days</p>
        </div>
        <div className="col-span-1 flex justify-center">
          <p className="text-md">Hours</p>
        </div>
        <div className="col-span-1 flex justify-center">
          <p className="text-md">Minutes</p>
        </div>
        <div className="col-span-1 flex justify-center">
          <p className="text-md">Seconds</p>
        </div>
      </div>
    </div>
    </div>
  );
}

export default CountdownTimer;
