import React from "react";
import MMWelcomeCard from "../../components/MMWelcome/MMWelcomeCard";
// import Button from "../../components/UI/Button/Button";

function VendorForm() {
  return (
    <MMWelcomeCard
      backgroundColor="bg-white"
      contentArea={
        <div>
          <VendorFormContent />
        </div>
      }
    />
  );
}

function VendorFormContent() {
  return (
    <form action="https://usebasin.com/f/986581e366c3" method="POST">
    <div className="relative md:p-6">
      <div className="">
        <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none">
          <div className="pt-8">
            <div>
              <h3 className="mt-2 text-3xl font-bold tracking-tight text-primary sm:text-4xl sm:tracking-tight">
              PLEASE FILL OUT THE FORM BELOW
              </h3>
            </div>
            <div>
              <h3 className="mt-4 text-xl tracking-tight text-primary sm:text-2xl sm:tracking-tight">
                Organization Information
              </h3>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-primary text-left"
                >
                  Company Name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="Company Name"
                    id="company-name"
                    autoComplete="given-name"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-primary text-left"
                >
                  Contact (Name, Title)
                </label>
                <div className="mt-1">
                  <input
                    id="contact"
                    name="Contact (Name, Title)"
                    type="text"
                    autoComplete="contact"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-primary text-left"
                >
                  Contact Email
                </label>
                <div className="mt-1">
                  <input
                    id="contact-email"
                    name="Contact Email"
                    type="email"
                    autoComplete="phone"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-primary text-left"
                >
                  Do you own an organization or business
                </label>
                <div className="mt-1">
                  <input
                    id="organization"
                    name="Do you own an organization or business"
                    type="text"
                    autoComplete="email"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-primary text-left"
                >
                  Address
                </label>
                <div className="mt-1">
                  <input
                    id="address"
                    name="Address"
                    type="text"
                    autoComplete="phone"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium text-primary text-left"
                >
                  City
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="City"
                    id="city"
                    autoComplete="address-level2"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="region"
                  className="block text-sm font-medium text-primary text-left"
                >
                  Province
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="Province"
                    id="province"
                    autoComplete="address-level1"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="postal-code"
                  className="block text-sm font-medium text-primary text-left"
                >
                  Postal code
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="Postal code"
                    id="postal-code"
                    autoComplete="postal-code"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-6">
                <label
                  htmlFor="website"
                  className="block text-sm font-medium text-primary text-left"
                >
                  Website
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="Website"
                    id="website"
                    autoComplete="website"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-primary text-left"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="Email address"
                    type="email"
                    autoComplete="email"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-primary text-left"
                >
                  Phone Number
                </label>
                <div className="mt-1">
                  <input
                    id="phone"
                    name="Phone Number"
                    type="text"
                    autoComplete="phone"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-6">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-primary text-left"
                >
                  Description of organization/business
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="Description of organization/business"
                    id="org-description"
                    autoComplete="given-name"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-6">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-primary text-left"
                >
                  Description of programs, services, and/or merchandise that
                  will be advertised and materials (e.g pamphlets, flyers, etc.)
                  that will be distributed:
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="Description of programs, services, and/or merchandise that
                    will be advertised and materials (e.g pamphlets, flyers, etc.)
                    that will be distributed:"
                    id="desc-program"
                    autoComplete="given-name"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>

            <div>
              <h3 className="mt-4 text-xl tracking-tight text-primary sm:text-2xl sm:tracking-tight">
                Supplementary Information
              </h3>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-primary text-left"
                >
                  Why would you like to be a Vendor at the RMHC? Are your
                  Programs/services targeted towards racialized parents?
                </label>
                <div className="mt-1">
                  <input
                    id="why-vendor"
                    name="Why would you like to be a Vendor at the RMHC? Are your
                    Programs/services targeted towards racialized parents?"
                    type="text"
                    autoComplete="email"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-6">
                <label
                  htmlFor="table-time"
                  className="block text-sm font-medium text-primary text-left"
                >
                  During what time would you like to have a table available
                  (Morning, Afternoon or All-Day) and how many people will be at
                  your table?{" "}
                </label>
                <div className="mt-1">
                  <input
                    id="table-time"
                    name="During what time would you like to have a table available
                    (Morning, Afternoon or All-Day) and how many people will be at
                    your table?"
                    type="text"
                    autoComplete="phone"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-6">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium text-primary text-left"
                >
                  Would you like to add an item to the attendee gift bags? If
                  yes, what is the item?
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="Would you like to add an item to the attendee gift bags? If
                    yes, what is the item?"
                    id="city"
                    autoComplete="address-level2"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-6">
                <label
                  htmlFor="region"
                  className="block text-sm font-medium text-primary text-left"
                >
                  Will you be selling items, if yes what item(s)
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="Will you be selling items, if yes what item(s)"
                    id="region"
                    autoComplete="address-level1"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>

            <div>
              <h3 className="mt-4 text-xl tracking-tight text-primary sm:text-2xl sm:tracking-tight">
              Social Media Handles

              </h3>
            </div>


            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-2">
                <label
                  htmlFor="postal-code"
                  className="block text-sm font-medium text-primary text-left"
                >
                  Facebook{" "}
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="Facebook"
                    id="facebook"
                    autoComplete="postal-code"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="postal-code"
                  className="block text-sm font-medium text-primary text-left"
                >
                  Twitter{" "}
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="Twitter"
                    id="twitter"
                    autoComplete="postal-code"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="postal-code"
                  className="block text-sm font-medium text-primary text-left"
                >
                  Instagram{" "}
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="Instagram"
                    id="instagram"
                    autoComplete="postal-code"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>

            <div>
              <h3 className="mt-4 text-xl tracking-tight text-primary sm:text-2xl sm:tracking-tight">
              Acknowledgement of Terms of Agreement


              </h3>
            </div>

            <div>
              <fieldset className="mt-10 space-y-5">
                <div className="relative flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="agreement"
                      aria-describedby="agreement"
                      name="Acknowledgement of Terms of Agreement"
                      type="checkbox"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="comments"
                      className="font-medium text-primary "
                    >
                     As an authorized agent of this business or organization, I have read and understood the RMHC Vendor Package and agree to abide by the terms and conditions listed therin.
                    </label>
                  </div>
                </div>
              </fieldset>
            </div>
            
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-primary text-left"
                >
                  Vendor Name
                </label>
                <div className="mt-1">
                  <input
                    id="vendor-name"
                    name="Vendor Name"
                    type="text"
                    autoComplete="email"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-6">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-primary text-left"
                >
                  Vendor Signature
                </label>
                <div className="mt-1">
                  <input
                    id="vendor-signature"
                    name="Vendor Signature"
                    type="text"
                    autoComplete="phone"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-6">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-primary text-left"
                >
                  Date
                </label>
                <div className="mt-1">
                  <input
                    id="date"
                    name="Date"
                    type="text"
                    autoComplete="phone"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 flex justify-center">
        <div className="pt-4">
          <button className="flex content-center justify-center px-8 py-3 border border-transparent text-base font-semi-bold rounded-lg text-white shadow-md bg-primary hover:opacity-75">
            SUBMIT
          </button>
        </div>
      </div>
    </div>
  </form>
  );
}

export default VendorForm;
