import React from "react";
import NavBar from "../Navigation/NavBar";

function OtherHeader({title,headerImage, description}) {
  return (
    <div className="relative bg-primary overflow-visible">
      <div className="sm:h-full sm:w-full max-w-7xl sm:overflow-hidden w-full md:w-auto">
        <div className="absolute inset-0">
          <img
            className="h-full w-full object-cover"
            src={headerImage}
            alt="header"
          ></img>
          <div className="absolute inset-0 bg-primary-light opacity-75 mix-blend-multiply" />
        </div>
      </div>
      <div className="relative pt-6 pb-16 sm:pb-24">
      <NavBar />
      <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
          <div className="text-center">
            <h1 className="text-4xl tracking-tight text-teritiary sm:text-3xl sm:tracking-tight md:text-4xl md:tracking-tight">
              <span className="block xl:inline font-bold uppercase">
                {title}
              </span>{" "}
            </h1>
            <p className="text-4xl mt-3 max-w-md mx-auto text-base text-teritiary  sm:text-3xl md:mt-5 md:text-4xl">
             {description}
            </p>
          </div>
        </main>
      </div>
    </div>
  );
}

export default OtherHeader;
