

import React from "react";
import MMWelcomeCard from "../../components/MMWelcome/MMWelcomeCard";

function BelowSpeakers() {
  return (
    <MMWelcomeCard
      backgroundColor="bg-primary"
      contentArea={
        <div>
          <BelowSpeakersContent />
        </div>
      }
    />
  );
}

function BelowSpeakersContent() {
  return (
    <div>
      <h1 className="space-y-5 pt-4">
        <span className="block mt-3 max-w-md mx-auto text-base text-white sm:text-3xl md:mt-5 text-4xl md:text-4xl md:max-w-3xl pb-4">
          RMHC2022 will include virtual speaker sessions and in-person speaker
          events for all participants.
        </span>

        {/* <span className="blockmt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          Our speakers will be shared soon.
        </span> */}
      </h1>

      <nav className="flex justify-center space-x-4 pt-10">
        <a
          href="/schedule"
          className="text-xl font-medium text-white hover:text-gray-600 underline"
        >
          View our full 2022 Program >
        </a>
        <span
          className="inline-block border-l border-gray-300"
          aria-hidden="true"
        />
        <a
          href="/faqs"
          className="text-xl font-medium text-white hover:text-gray-600 underline"
        >
          FAQs >
        </a>
      </nav>
      <a href="https://rmhc2022.eventbrite.ca">
        <button className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-primary bg-teritiary hover:opacity-75 w-auto tracking-wide">
          REGISTER NOW
        </button>
      </a>
    </div>
  );
}

export default BelowSpeakers;

