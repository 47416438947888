import React from "react";
import aboutTwo from "../../assets/images/conference/aboutTwo.png";

function AboutBottomImage() {
  return (
    <div>
      <AboutBottomImageContent />
    </div>
  );
}

function AboutBottomImageContent() {
  return (
    <div className="mx-auto">
      <img
        className="h-96 w-full object-cover"
        src={aboutTwo}
        alt="header"
      ></img>
    </div>
  );
}

export default AboutBottomImage;
