import React from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import ArtExhibitPhoto from "../../assets/images/conference/ArtExhibit.jpg";
import SpeakerOne from "../../assets/images/conference/Speakers/SpeakerOne.png";

const artist = {
  name: "Solana Cain",
  title: "",
  imageUrl: SpeakerOne,
  description:
    "Solana Cain is a photojournalist, emerging curator, and photographic professional. She is an accomplished photo editor, currently working at The Globe and Mail newspaper in Toronto. Solana is committed to her photojournalism practice, where she prioritizes documenting authentic and empowering images of Black women and girls.",
};
function ArtExhibit() {
  return (
    <div className="relative bg-white">
      <div className="h-56 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <img
          className="h-full w-full object-cover"
          src={ArtExhibitPhoto}
          alt=""
        />
      </div>
      <div className="relative mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8 lg:py-6">
        <div className="md:ml-auto md:w-1/2 md:pl-10 space-y-4">
          <p className="text-3xl font-bold tracking-wide sm:text-4xl text-primary">
            RMHC SOCIAL NIGHT
          </p>

          <p className="mt-3 text-lg text-gray-500 pt-10 pb-5">
            {" "}
            The RMHC Social will be an evening of bonding, celebration and
            knowledge sharing for our RMHC family. It will be a night of music,
            art, and many other activities to remind us of all of the joy that
            is experienced in the perinatal health sector. Get ready to take part in this eye opening experience at our RMHC Social Night. Enjoy this exhibit while you connect and engage with other attendees!
          </p>

          <p className="mt-3 text-lg text-gray-500">
            This year at the Racialized Maternal Health Conference 2022 we will
            be sharing the work of the artist/photographer Solana Cain. Solana
            Cain has captured Black Maternal Health exquisitely in this exhibit
            titled <b>Honouring Black Motherhood</b>.
          </p>
          <h2 className="text-lg font-semibold pt-4 text-indigo-600">
            About the Artist
          </h2>
          <div className="space-y-4 sm:grid sm:grid-cols-2 sm:items-start sm:gap-6 sm:space-y-0">
            <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
              {/* <img
                className="rounded-xl object-cover shadow-lg"
                src={artist.imageUrl}
                alt={artist.name}
              /> */}
            </div>
            <div className="sm:col-span-2">
              <div className="space-y-4">
                <div className="text-lg text-justified">
                  <p className="text-gray-500">{artist.description}</p>
                </div>
              </div>
            </div>
          </div>
          <a href="/art-exhibit">
            <button className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary hover:opacity-75 w-auto">
              FIND OUT MORE
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default ArtExhibit;
