import React from "react";
import MMWelcomeCard from "../../components/MMWelcome/MMWelcomeCard";

function WhatYouWillFind() {
  return (
    <MMWelcomeCard
      backgroundColor="bg-primary"
      contentArea={
        <div>
          <WhatYouWillFindContent />
        </div>
      }
    />
  );
}
const WhatYouWillFindContent = () => {
  return (
    <div className="mx-auto max-w-md text-center lg:max-w-7xl bg-primary pb-10 pt-10">
      <p className="text-3xl font-bold tracking-wide sm:text-4xl text-white">
        WHAT YOU'LL FIND AT RMHC
      </p>
      <p className="mt-5 max-w-prose mx-auto text-2xl text-teritiary">
        Every year, professionals, students, experts, researchers, policymakers,
        and other stakeholders will come together to pursue efforts to improve
        racialized maternal-newborn health and practices in Canada by promoting
        best practices, cultural competency and advocacy for racialized maternal
        health issues, research and policies.
      </p>
    </div>
  );
};

export default WhatYouWillFind;
