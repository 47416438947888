import React, { useEffect } from "react";
// import HeaderNew from "../../components/UI/HeaderNew";
import Footer from "../LandingPage/Footer";
import AboutAbstract from "./AboutAbstract";
import AbstractForm from "./AbstractForm";
import KeyDates from "./KeyDates";
import SubmissionGuidelines from "./SubmissionGuidelines";
import ConferenceHeader from "../../assets/images/conference/ConferenceHeader.jpeg";
import OtherHeader from "../../components/UI/Headers/OtherHeader";

function SubmitAbstract() {
  useEffect(() => {
    // should this be removed? theres gotta be a better way to do this. If not, I don't think its worth the messy code
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();
        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  });
  return (
    <>
      <div className="overflow-auto md:overflow-scroll ...">
        <OtherHeader
          title={"2022 Conference Abstract"}
          description={
            "Submissions Closed"}
          headerImage={ConferenceHeader}
        />
        {/* <AboutAbstract /> */}
        {/* <KeyDates /> */}
        {/* <SubmissionGuidelines /> */}
        {/* <AbstractForm /> */}

        <Footer />
      </div>
    </>
  );
}

export default SubmitAbstract;
