import React from "react";
import MMWelcomeCard from "../../components/MMWelcome/MMWelcomeCard";
import { CameraIcon } from "@heroicons/react/20/solid";
import SolanaImage from "../../assets/images/conference/SolanaCain.jpg";
import ArtImageTwo from "../../assets/images/conference/ArtExhibitTwo.jpg";
import ArtImageOne from "../../assets/images/conference/ArtExhibit.jpg";

function AboutArtShow() {
  return (
    <MMWelcomeCard
      backgroundColor="bg-white"
      contentArea={
        <div>
          <AboutArtShowContent />
        </div>
      }
    />
  );
}

function AboutArtShowContent() {
  return (
    <div className="md:bg-white">
      <div className="overflow-hidden bg-white">
        <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="absolute top-0 bottom-0 left-3/4 hidden w-screen lg:block" />
          <p className="mt-8 text-xl leading-8 text-gray-500">
            This year at the Racialized Maternal Health Conference 2022 we will
            be sharing the work of the artist/photographer Solana Cain. Solana
            Cain has captured Black Maternal Health exquisitely in this exhibit
            titled <b>Honouring Black Motherhood.</b> Enjoy this exhibit while you connect and engage with
            other attendees! We are pleased to share that the evening event portion of last year’s conference is taking place on February 3, 2023 at The Globe and Mail Centre in Toronto. If you are a 2022 RMHC ticket holder, your ticket to this special event is free. A special code has been sent to all conference ticket holders to reserve your ticket. Limited tickets are also available for purchase.
          </p>
          <div className="pt-10 pb-10">
            <img
              className="w-full rounded-lg"
              src={ArtImageTwo}
              alt=""
              width={1310}
              height={873}
            />
          </div>
          <div>
            <h2 className="text-lg font-semibold text-indigo-600">
              About the Artist
            </h2>
            <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-primary sm:text-4xl">
              Solana Cain
            </h3>
          </div>
          <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8"></div>
          <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative lg:col-start-2 lg:row-start-1">
              <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
                <figure>
                  <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                    <img
                      className="rounded-lg object-cover object-center shadow-lg"
                      src={SolanaImage}
                      alt="Whitney leaning against a railing on a downtown street"
                      width={1184}
                      height={1376}
                    />
                  </div>
                </figure>
              </div>
            </div>
            <div className="mt-8 lg:mt-0">
              <div className="prose prose-indigo mx-auto mt-5 text-gray-500 lg:col-start-1 lg:row-start-1 lg:max-w-none text-left">
                <p className="text-gray-500 text-left pb-4">
                  Solana Cain is a photojournalist, emerging curator, and
                  photographic professional. Solana was selected as one of the
                  inaugural artists in residence at Nia Centre for the Arts in
                  Toronto. She is a graduate of the School of Journalism at
                  Toronto Metropolitan University, and the photojournalism
                  program at Loyalist College. Solana is an accomplished photo
                  editor, currently working at The Globe and Mail newspaper in
                  Toronto.
                </p>
                <p className="text-gray-500 text-left">
                  “Honouring Black Motherhood” is a continuation of Solana’s
                  documentary work aimed at celebrating and empowering Black
                  Mothers. For this photo series, Solana worked collaboratively
                  with mothers documenting them while they shared one of their
                  favourite activities to do with their infant. Solana strives
                  to record the intimacy and joy along with strength and
                  resiliency that Black mothers possess. These photos were
                  featured in Maclean’s magazine (2021) and in Broadview
                  Magazine (2020). This is the second time Solana is exhibiting
                  photography at the Racialized Maternal Health Conference.
                </p>
                <p className="pt-2">
                  Website:{" "}
                  <a href="https://www.solanacain.com/">
                    <button className="underline">www.solanacain.com</button>
                  </a>
                </p>
                <p>E-mail: solanacain@gmail.com</p>
                <p>
                  Instagram:{" "}
                  <a href="https://www.instagram.com/_solanacain/">
                    <button className="underline">@_solanacain</button>
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="pt-10">
            <img
              className="w-full rounded-lg"
              src={ArtImageOne}
              alt=""
              width={1310}
              height={873}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutArtShow;
