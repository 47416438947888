import React, { useEffect } from "react";
// import Header from "./Header";
import AboutConference from "./AboutConference";
import Footer from "./Footer";
import ProgramAndFAQs from "./ProgramAndFAQs";
import Speakers from "./Speakers";
import WhatYouWillFind from "./WhatYouWillFind";
import GetInvolved from "./GetInvolved";
import MoreInformation from "./MoreInformation";
import HomeHeader from "../../components/UI/Headers/HomeHeader";
import ArtExhibit from "./ArtExhibit";
import BelowSpeakers from "./BelowSpeakers";
// import HeaderMenu from "../../components/UI/HeaderMenu";

function LandingPage() {
  useEffect(() => {
    // should this be removed? theres gotta be a better way to do this. If not, I don't think its worth the messy code
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();
        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  });
  return (
    <>
      <div className="overflow-auto md:overflow-scroll ...">
        {/* {mommyMonitorAnimatedLogo} */}
        <HomeHeader />
        <AboutConference />
        <Speakers />
        <BelowSpeakers />
        <ArtExhibit />
        <WhatYouWillFind />
        <MoreInformation/>
        <GetInvolved />
        <ProgramAndFAQs />
        <Footer />
      </div>
    </>
  );
}

export default LandingPage;
