import React  from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import "./App.css";
import About from "./containers/About/About";
import ArtShow from "./containers/ArtShow/ArtShow";
import ContactUs from "./containers/ContactUs/ContactUs";
import DonorsAndPartners from "./containers/DonorsAndPartners/DonorsAndPartners";
import FAQs from "./containers/FAQs/FAQs";
import FullProgram from "./containers/FullProgram/FullProgram";
import InnovationShowCase from "./containers/InnovationShowcase/InnovationShowCase";
import LandingPage from "./containers/LandingPage/LandingPage";
import Media from "./containers/Media/Media";
import OralPresentations from "./containers/OralPresentations/OralPresentations";
import PosterPresentations from "./containers/PosterPresentations/PosterPresentations";
import Speakers from "./containers/Speakers/Speakers";
import Sponsors from "./containers/Sponsors/Sponsors";
import SubmitAbstract from "./containers/SubmitAbstract/SubmitAbstract";
import SubmitVendor from "./containers/SubmitVendor/SubmitVendor";
import VirtualConference from "./containers/VirtualConference/VirtualConference";

function App(props) {
  let routes = (
    <Route
      render={({ location }) => (
        <Switch location={location}>
          <Route path="/" exact component={LandingPage} />
          <Route path="/submit-abstract" component={SubmitAbstract} />
          <Route path="/submit-vendor" component={SubmitVendor} />
          <Route path="/sponsors" component={Sponsors} />
          <Route path="/faqs" component={FAQs} />
          <Route path="/about" component={About} />
          <Route path="/virtual-conference" component={VirtualConference} />
          {/* <Route path="/poster-presentations" component={PosterPresentations} /> */}
          {/* <Route path="/oral-presentations" component={OralPresentations} /> */}
          <Route path="/art-exhibit" component={ArtShow} />
          <Route path="/innovation-showcase" component={InnovationShowCase} />
          <Route path="/media" component={Media} />
          <Route path="/contact-us" component={ContactUs} />
          <Route path="/donors-partners" component={DonorsAndPartners} />
          <Route path="/speakers" component={Speakers} />
          <Route path="/schedule" component={FullProgram} />
          <Redirect to="/" />
        </Switch>
      )}
    />
  );

  return (
    // if a person isnt logged in dont show home
    <div className="App">{routes}</div>

  );
}
export default withRouter(App);
