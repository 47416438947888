import React, { useEffect } from "react";
// import HeaderNew from "../../components/UI/HeaderNew";
import Footer from "../LandingPage/Footer";
import VirtualHeader from "../../assets/images/conference/VirtualHeader.png";
import InnovationShowCaseForm from "./InnovationShowCaseForm";
import OtherHeader from "../../components/UI/Headers/OtherHeader";

function InnovationShowCase() {
  useEffect(() => {
    // should this be removed? theres gotta be a better way to do this. If not, I don't think its worth the messy code
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();
        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  });
  return (
    <>
      <div className="overflow-auto md:overflow-scroll ...">
        {/* {mommyMonitorAnimatedLogo} */}
        <OtherHeader
          title={"2022 Innovation Showcase"}
          description={"Submissions now open"}
          headerImage={VirtualHeader}
        />
        <InnovationShowCaseForm />
        <Footer />
      </div>
    </>
  );
}

export default InnovationShowCase;
