import React from "react";
import MMWelcomeCard from "../../components/MMWelcome/MMWelcomeCard";

function AboutVirtual() {
  return (
    <MMWelcomeCard
      backgroundColor="bg-white"
      contentArea={
        <div>
          <AboutVirtualContent />
        </div>
      }
    />
  );
}

function AboutVirtualContent() {
  return (
    <div className="md:bg-white md:p-6">
      <div className="text-primary lg:max-w-none">
        <p className="mt-8 text-2xl text-primary leading-8 pb-10">
        The 2022 Racialized Maternal Health Conference will be screened online through our website and facebook page. Registration for the virtual conference is required.*
        </p>
        <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 flex justify-center">
        <div className="pt-4">
          <a href="https://rmhc2022.eventbrite.ca">
          <button className="tracking-wide flex content-center justify-center px-8 py-3 border border-transparent text-base font-semi-bold rounded-lg text-white shadow-md bg-primary hover:opacity-75">
            REGISTER NOW
          </button>
          </a>  
        </div>
      </div>
      </div>
    </div>
  );
}

export default AboutVirtual;
