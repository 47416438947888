import React from "react";
import MMWelcomeCard from "../../components/MMWelcome/MMWelcomeCard";
import Daymark from "../../assets/images/conference/Sponsors/Daymark.png";
import MCCS from "../../assets/images/conference/Sponsors/MCCS-logo.png";

function AboutDonorsAndPartners() {
  return (
    <MMWelcomeCard
      backgroundColor="bg-white"
      contentArea={
        <div>
          <AboutDonorsAndPartnersContent />
        </div>
      }
    />
  );
}

function AboutDonorsAndPartnersContent() {
  return (
    <div className="md:bg-white md:p-6">
      <div className="text-primary lg:max-w-none">
        <h3 className="mt-2 text-3xl font-bold tracking-wide text-primary sm:text-4xl sm:tracking-tight">
          THANK YOU !
        </h3>
        <p className="mt-8 text-2xl text-primary leading-8">
          We are continuously grateful to our donor partners and sponsors for
          making this conference possible.{" "}
        </p>

        <p className="text-2xl text-primary leading-8 pg-10">
        If you are interested in becoming a sponsor send us an email at <a href="mailto: conference@mommymonitor.ca" className="text-blue-700 underline">conference@mommymonitor.ca</a> for more information
        </p>
        <div className="pt-4">
          <div className="mt-6 grid grid-cols-2 gap-2 md:grid-cols-2 lg:mt-8">
            <div className="col-span-1 flex justify-center py-8 px-8">
              <img className="max-h-24" src={Daymark} alt="Workcation" />
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8">
              <img className="max-h-30" src={MCCS} alt="Ministry of Health " />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutDonorsAndPartners;
