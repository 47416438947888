import React from "react";
import MMWelcomeCard from "../../components/MMWelcome/MMWelcomeCard";

function AboutVendor() {
  return (
    <MMWelcomeCard
      backgroundColor="bg-white"
      contentArea={
        <div>
          <AboutVendorContent />
        </div>
      }
    />
  );
}

function AboutVendorContent() {
  return (
    <div className="relative md:bg-white md:p-6">
      <div className="">
        <div className="prose prose-lg lg:max-w-none">
        <div>
              <h3 className="mt-2 text-3xl font-bold tracking-wide text-primary sm:text-4xl sm:tracking-tight">
                FORM INFORMATION
              </h3>
            </div>
          <p className="mt-8 text-2xl leading-8 pb-10">
          Vendors for the 2022 Racialized Maternal Health Conference should fill out the form if interested and the vendor package and terms will be provided. In order to be considered for a Vendor spot at this conference, you must submit the Vendor Application form found below to: <a href="mailto: conference@mommymonitor.ca" className="text-blue-700 underline">conference@mommymonitor.ca</a> Submitting an application does not guarantee a reservation of vendor space. You will receive the vendor package and terms and a confirmation email if your application is successful. This email will contain the registration link and registration code that will allow you to formally register as a vendor.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutVendor;
